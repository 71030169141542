/** @jsx jsx */
// import React from "react"
import { Styled, jsx } from "theme-ui";
import { Link } from "gatsby";

import Main from "../components/Main";
import SEO from "../components/SEO";
import Title from "../components/Title";
import Container from "../components/Container";

const SecondPage = () => (
  <Main>
    <SEO title="Page two" />

    <Container>
      <Title titleSub="Unser Team" titleName="Kompetenz und Sachlichkeit gepaart mit Leidenschaft." titleLink="Jetzt bewerben" titleURL="mail@rae-blk.de" />

      <Styled.p>Welcome to page 2...</Styled.p>
      <Styled.p>
        <Link to="/">Go back to the homepage</Link>
      </Styled.p>
    </Container>
  </Main>
);

export default SecondPage;
